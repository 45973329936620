
<style scoped>
	.body {
		background-color: #F1F3F6;
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.login-body {
		width: 300px;
		height: 600px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.title {
		font-size: 30px;
		line-height: 60px;
		width: 100%;
		font-weight: bold;
		text-align: center;
	}
	.sub-title {
		width: 100%;
		text-align: center;
		font-size: 14px;
		color: #999999;
	}
	
	.login-type {
		margin-top: 10px;
		color: #1677FF;
		font-size: 14px;
		height: 40px;
		line-height: 40px;
		border-bottom: solid 2px #1677FF;
	}
	
	.inp-box {
		margin-top: 24px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 380px;
		height: 40px;
		border: solid 1px #dcdcdc;
		border-radius: 5px;
		background-color: white;
	}

	.inp-icon {
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 20px;
		color: #1677FF;
	}	
	.inp {
		outline: none;
		border-style: none;
		height: 38px;
		width: 300px;
		text-indent: 0px;
		font-size: 16px;
	}
	.inp1 {
		outline: none;
		border-style: none;
		height: 38px;
		width: 210px;
		text-indent: 0px;
		font-size: 16px;
		overflow: hidden;
	}
	input::-webkit-input-placeholder {
	    color: #dcdcdc; /* WebKit browsers */
	}
	input:-moz-placeholder { /* Firefox 18- */
	    color: #dcdcdc; /* Firefox 19+ */
	}
	input:-ms-input-placeholder { /* IE 10+ */
	    color: #dcdcdc;
	}
	.img-code {
		height: 40px;
		width: 130px;
	}
	.cl_grey {
		color: #dcdcdc;
	}
	
	.login-btn {
		margin-top: 30px;
		line-height: 38px;
		width: 380px; height: 38px;
		color: white;
		text-align: center;
		font-size: 14px;
		background-color: #1677FF;
		border-radius: 5px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
	}
	
	.check-box {
		width: 380px;
		margin-top: 30px;
		font-size: 14px;
	}
	.version {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 14px;
		color: #999999;
	}
</style>

<template>
	<div class="body">
		<div class="login-body">
			<div class="title">Rich Gold-TGO</div>
			<div class="sub-title">Rich Gold-TGO后台管理系统</div>
			<div class="login-type">账号密码登录</div>
			<div class="inp-box">
				<div class="inp-icon"><i class="el-icon-user"></i></div>
				<input class="inp" v-model="username" placeholder="用户名"/>
			</div>
			<div class="inp-box">
				<div class="inp-icon"><i class="el-icon-lock"></i></div>
				<input class="inp" :type="viewPass" v-model="password" placeholder="密码"/>
				<div class="inp-icon cl_grey" @click="eyes"><i class="el-icon-view"></i></div>
			</div>
			<div class="inp-box">
				<div class="inp-icon"><i class="el-icon-document"></i></div>
				<input class="inp1" v-model="vcode" placeholder="验证码"/>
				<img class="img-code" src="../assets/vcode.png"/>
			</div>
			
			<div class="check-box"><el-checkbox v-model="remember">保持登录</el-checkbox></div>
			
			<div class="login-btn" @click="login">登录</div>
		</div>
		
		<div class="version">当前版本号：1.0.0</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			username: '',
			password: '',
			vcode:'',
			remember: false,
			viewPass:'password',
		};
	},
	mounted() {
		this.$nextTick(() => {
			let remember = localStorage.getItem("rememberLogin");
			if(remember == 1)
			{
				this.username = localStorage.getItem("username");
				this.password = localStorage.getItem("password");
				this.remember = true;
			}
		});
	},
	methods: {
		eyes(){
			this.viewPass = ( this.viewPass == 'password' )? 'text' : 'password';
		},
		remembers(){
			if(this.remember === true)
			{
				localStorage.setItem("rememberLogin", 1);
				localStorage.setItem("username", this.username);
				localStorage.setItem("password", this.password);
			}else{
				localStorage.setItem("rememberLogin", 0);
				localStorage.removeItem("username");
				localStorage.removeItem("password");
			}
		},
		login() {
			let _this = this;
			if(_this.username.length < 2 || _this.password.length < 5)
			{
				return _this.$message({
					 message: '请输入正确的账号密码哟~',
					 type: 'warning',
				});
			}
			if(_this.vcode.length == 0){
				return _this.$message({
					 message: '请输入验证码~',
					 type: 'warning',
				});
			}

			const loading = this.$loading({
					lock: true,
					text: '登录中',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			_this.$api.post("/Login/LoginApi", {username:_this.username, password:_this.password, vcode:_this.vcode}).then(res=>{
				loading.close();
				if(res.code == 1)
				{
					localStorage.setItem("MENU_LIST", JSON.stringify( res.data.permission_list) );
					_this.$store.commit("loginSuccess", res.data);
					_this.remembers();
					_this.$message({
						 message: '登录成功~',
						 type: 'success',
					});
					setTimeout(()=>{
						_this.$router.push("/Dashboard");
					}, 1000);
				}else{
					return _this.$message({
						 message: res.msg,
						 type: 'warning',
					});
				}
				
			}).catch(err=>{
				loading.close();
			});
		},
	},
};
</script>

