import { render, staticRenderFns } from "./promotionRecord.vue?vue&type=template&id=7152f569&"
import script from "./promotionRecord.vue?vue&type=script&lang=js&"
export * from "./promotionRecord.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports