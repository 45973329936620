<style scoped>
	#app {
		padding: 10px 20px 20px 20px;
	}
	.header {
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		
	}
	.line {
		display: flex; flex-direction: row; flex-grow: 1; justify-content: space-between;
		margin-bottom: 20px;
	}
	.box1 {
		width: 360px; height: 280px; background-color: white;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		display: flex;
		flex-direction: column;
	}
	.box4 {
		width: 360px; height: 290px; background-color: white;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		display: flex;
		flex-direction: column;
	}
	.box3 {
		width: calc(100% - 380px); background-color: white;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		display: flex;
		flex-direction: column;
	}
	.box2 {
		min-width: 400px;
		width: 49%; height: 280px; background-color: white;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		margin-left: 10px;
		margin-right: 10px;
	}
	.box-header {
		border-bottom: solid 1px #F6F6F6;
		height: 60px;
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		text-indent: 20px;
	}
	.box-p {
		height: 280px; width: calc(100% - 370px); display: flex; flex-direction: row; justify-content: space-between;
	}
	.user-box {
		width: 100%;
		text-align: center;
		height: 120px;
		line-height: 120px;
		font-size: 28px;
		color: #1677FF;
		font-weight: bold;
	}
	.user-line {
		display: flex;
		flex-direction: row;
	}
	.user-col {
		width: 50%;
	}
	.user-item {
		text-align: center;
		line-height: 30px;
		font-size: 14px;
	}
	
	.tr {
		display: flex;
		flex-direction: row;
		border-bottom: solid 1px #F6F6F6;
	}
	.th {
		background-color: #FbFbFb;
		width: 25%;
		height: 50px;
		line-height: 50px;
		text-indent: 20px;
		font-weight: bold;
		font-size: 15px;
	}
	.td {
		width: 25%;
		height: 50px;
		line-height: 50px;
		text-indent: 20px;
		font-size: 14px;
	}
	.b {
		font-weight: bold;
	}
</style>
<template>
	<div id="app">
		<div class="header">首页</div>
		<div class="line">
			<div class="box1">
				<div class="box-header">用户总数</div>
				<div class="user-box">61211</div>
				<div class="user-line">
					<div class="user-col">
						<div class="user-item">372</div>
						<div class="user-item">昨日新增用户</div>
					</div>
					<div class="user-col">
						<div class="user-item">372</div>
						<div class="user-item">昨日新增用户</div>
					</div>
				</div>
			</div>
			<div class="box-p"> 
				<div class="box2">
					<div class="box-header">充值</div>
				</div>
				<div class="box2">
					<div class="box-header">提现</div>
				</div>
			</div>
		</div>
		
		<div class="line">
			<div class="box4">
				<div class="box-header">统计</div>
				<div class="table">
					<div class="tr">
						<div class="th">类型</div>
						<div class="th">昨日</div>
						<div class="th">今日</div>
						<div class="th">总的</div>
					</div>
					<div class="tr">
						<div class="td">新客活跃</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">老客活跃</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">首充数</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
				</div>
			</div>
			<div class="box3"> 
				<div class="box-header">资金流水</div>
				
				<div class="table">
					<div class="tr">
						<div class="th">类型</div>
						<div class="th">昨日</div>
						<div class="th">今日</div>
						<div class="th">总的</div>
					</div>
					<div class="tr">
						<div class="td">充值金额</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">提现金额</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">真是提现金额</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">新客充值金额</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">老客充值金额</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">邀请充值金额</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">非邀请充值金额</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">充值数量</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">提现数量</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
					<div class="tr">
						<div class="td">真是提现数量</div>
						<div class="td">0</div>
						<div class="td">0</div>
						<div class="td b">0</div>
					</div>
				</div>
			</div>
		</div>
		
		
		
	</div>
</template>

<script>
	
	
	
	export default {
		name:'Dashboard',
		data(){
			return {
			}
		},
		mounted() {
			this.$nextTick(() => {
			});
			
		},
		created() {
		},
		beforeDestroy() {
		},
		methods:{
			formatTimestamp(timestamp) {
				// 将时间戳转换为毫秒
				const date = new Date(timestamp * 1000);
				// 获取日期和时间的各个部分
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				// 格式化为字符串
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			},
			refresh(){
				console.log("refresh click");
				this.getData();
			}
		}
	}
</script>

