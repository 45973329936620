<template>
	<div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
			
			<el-form-item label="总金额:" prop="total_money">
				<el-input type="number" style="width: 700px;" size="small" v-model="ruleForm.total_money"></el-input>
			</el-form-item>
			
			<el-form-item label="总人数:" prop="total_count">
				<el-input type="number" style="width: 700px;" size="small" v-model="ruleForm.total_count"></el-input>
			</el-form-item>
			
			<el-form-item label="最小金额:" prop="low_money">
				<el-input type="number" style="width: 700px;" size="small" v-model="ruleForm.low_money"></el-input>
			</el-form-item>
			
			<el-form-item label="最大金额:" prop="high_money">
				<el-input type="number" style="width: 700px;" size="small" v-model="ruleForm.high_money"></el-input>
			</el-form-item>
			
			<el-form-item label="备注:" prop="remark">
				<el-input style="width: 700px;" size="small" v-model="ruleForm.remark"></el-input>
			</el-form-item>
			

			<el-form-item style="display: flex; flex-direction: row; justify-content: flex-end;">
				<el-button @click="resetForm('ruleForm')">重置</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
				
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
	    data() {
	      return {
			ruleForm: {
				total_money: '',
				total_count: '',
				low_money: '',
				high_money: '',
				remark:'',
	        },
	        rules: {
	          total_money: [{required: true, message: '请输入总金额', trigger: 'blur'}],
	          total_count: [{required: true, message: '请输入总人数', trigger: 'blur'}],
	          low_money: [{required: true, message: '请输入最小金额', trigger: 'blur'}],
			  high_money: [{required: true, message: '请输入最大金额', trigger: 'blur'}],
			  remark: [{required: true, message: '请输入备注', trigger: 'blur'}]
	        }
	      };
	    },
	    methods: {
			submitApi(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Red/addRed", this.ruleForm).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '添加成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			submitForm(formName) {
				let _this = this;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						_this.submitApi();
					} else {
						return that.$message({
							 message: '提交失败',
							 type: 'warning',
						});
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
	    }
	  }
</script>

<style scoped>
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    min-width: 90px;
	    height: 90px;
	    line-height: 90px;
	    text-align: center;
	  }
	  .avatar {
	    min-width: 90px;
	    height: 90px;
	    display: block;
	  }
</style>