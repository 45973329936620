<template>
	<div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
			<el-form-item label="名称:" prop="name">
				<el-input size="small" v-model="ruleForm.name"></el-input>
			</el-form-item>
			
			<el-form-item label="图片:" prop="img_url">
				<el-upload class="avatar-uploader" :action="this.$http_host + '/Admin/Upload/UploadImage'" :headers="headers" :show-file-list="false"
					:on-success="UploadSuccess"
					:before-upload="beforeUpload"
					:on-error="UploadError"
				>
				  <img v-if="ruleForm.img_url" :src="this.$http_host + ruleForm.img_url" class="avatar">
				  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			
			<el-form-item label="购买金额:" prop="price">
				<el-input type="number" size="small" placeholder="请输入" v-model="ruleForm.price">
				    <template slot="prepend">XOF</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="周期(单位:天):" prop="days">
				<el-input type="number" size="small" placeholder="请输入" v-model="ruleForm.days">
				    <template slot="append">天</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="库存:" prop="stock">
				<el-input type="number" size="small" placeholder="请输入" v-model="ruleForm.stock">
				    <template slot="append">件</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="每小时收益金额:" prop="hour_earn">
				<el-input type="number" size="small" placeholder="请输入" v-model="ruleForm.hour_earn">
				    <template slot="prepend">XOF</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="提现凭证奖励金额:" prop="withdraw_earn">
				<el-input type="number" size="small" placeholder="请输入" v-model="ruleForm.withdraw_earn">
				    <template slot="prepend">XOF</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="每人购买次数:" prop="limit_times">
				<el-input type="number" size="small" placeholder="请输入" v-model="ruleForm.limit_times">
				    <template slot="append">次</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="折扣(5=5%):" prop="discount">
				<el-input type="number" size="small" placeholder="请输入" v-model="ruleForm.discount">
				    <template slot="append">%</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="是否置顶:" prop="is_top">
				<el-switch v-model="ruleForm.is_top" active-value="1" inactive-value="0"></el-switch>
			</el-form-item>
			<el-form-item label="是否返回本金:" prop="is_back">
				<el-switch v-model="ruleForm.is_back" active-value="1" inactive-value="0"></el-switch>
			</el-form-item>
			<el-form-item label="到期领取收益:" prop="is_time">
				<el-switch v-model="ruleForm.is_time" active-value="1" inactive-value="0"></el-switch>
			</el-form-item>
			<el-form-item label="是否免费:" prop="is_free">
				<el-switch v-model="ruleForm.is_free" active-value="1" inactive-value="0"></el-switch>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
				<el-button @click="resetForm('ruleForm')">重置</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
	    data() {
	      return {
			  headers:{},
			  
			ruleForm: {
				name: '',
				img_url: '',
				price: '',
				days: '',
				stock:'',
				hour_earn:'',
				withdraw_earn:'',
				limit_times:'',
				discount:'',
				is_top: 0,
				is_back: 0,
				is_time: 0,
				is_free: 0,
				details:''
	        },
	        rules: {
	          name: [
	            { required: true, message: '请输入名称', trigger: 'blur' },
	          ],
	          img_url: [
	            { required: true, message: '请上传图片', trigger: 'blur' }
	          ],
	          price: [
	            { required: true, message: '请输入购买金额', trigger: 'blur' }
	          ],
	          days: [
	            { required: true, message: '请输入周期', trigger: 'blur' }
	          ],
	          stock: [
	            { required: true, message: '请输入库存', trigger: 'blur' }
	          ],
	          hour_earn: [
	            { required: true, message: '请输入每小时收益金额', trigger: 'change' }
	          ],
			  withdraw_earn: [
			    { required: true, message: '请输入提现凭证奖励金额', trigger: 'change' }
			  ],
			  limit_times: [
			    { required: true, message: '请输入每人购买次数', trigger: 'change' }
			  ],
			  discount: [
			    { required: true, message: '请输入折扣', trigger: 'change' }
			  ],
	          details: [
	            { required: true, message: '请输入介绍', trigger: 'blur' }
	          ]
	        }
	      };
	    },
		created() {
			this.headers.Authorization = localStorage.getItem('access_token');
		},
	    methods: {
			beforeUpload(file){
			},
			UploadSuccess(res, file, fileList){
				if(res.code == 200)
				{
					this.ruleForm.img_url = res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadError(){
				return this.$message({ message: '上传失败，请重试', type: 'warning' });
			},
			submitApi(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Product/addProduct", this.ruleForm).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '添加成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			submitForm(formName) {
				let _this = this;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						_this.submitApi();
					} else {
						return that.$message({
							 message: '提交失败',
							 type: 'warning',
						});
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
	    }
	  }
</script>

<style >
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    min-width: 90px;
	    height: 90px;
	    line-height: 90px;
	    text-align: center;
	  }
	  .avatar {
	    min-width: 90px;
	    height: 90px;
	    display: block;
	  }
</style>