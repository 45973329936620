import Vue from 'vue'
import Router from 'vue-router'
import MainFrame from '@/components/MainFrame'
import Login from '@/components/Login'

//数据面板
import Dashboard from '@/components/Dashboard/Dashboard'

//开发管理
import MenuList from '@/components/Dev/MenuList'

//运营管理
import operations from '@/components/Dashboard/operations'

//资金流水
import recharge from '@/components/Recharge/recharge'
import withdrawal from '@/components/Recharge/withdrawal'
import withdrawal_cert from '@/components/Recharge/withdrawal_cert'

// 租赁管理
import rentList from '@/components/Rent/rentList'
import rentRecord from '@/components/Rent/rentRecord'

//促销管理
import promotionList from '@/components/Promotion/promotionList'
import promotionRecord from '@/components/Promotion/promotionRecord'

//系统管理
import system from '@/components/System/system'
import service from '@/components/System/service'

//额度管理
import rechargeConfig from '@/components/Recharge/rechargeConfig'


//用户管理
import UserList from '@/components/User/userList'
import Contacts from '@/components/User/Contacts'

//财务管理
import OrderList from '@/components/Order/orderList'
import ClosingOrder from '@/components/Order/ClosingOrder'

// 红包管理
import redList from '@/components/Red/redList'
import redRecord from '@/components/Red/redRecord'

// 管理员管理
import WinOrLose from '@/components/report/WinOrLose'
import PayOrDraw from '@/components/report/PayOrDraw'
import FundReport from '@/components/report/FundReport'

// 管理员管理
import managerList from '@/components/Manager/managerList'

// 权限管理
import permissionList from '@/components/Permission/permissionList'
import roleList from '@/components/Permission/roleList'

// 支付管理
import PaywayList from '@/components/Payway/PaywayList'


import App from '@/App.vue'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    // 处理错误 跳转到重复路径上，忽略错误
    // console.error(err);
  });
};

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'App',
      component: App,
	  children:[
		  {
			path:'',
			component: MainFrame,
		  },
		  {
			name: 'Home',
			path: 'Home',
			component: MainFrame,
			children:[
				{
					path:'/Dashboard',
					name:'Dashboard',
					component:Dashboard
				},{
					path:'/Dev/MenuList',
					name:'MenuList',
					component:MenuList
				},{
					path:'/operations',
					name:'operations',
					component:operations
				},{
					path:'/recharge',
					name:'recharge',
					component:recharge
				},{
					path:'/withdrawal',
					name:'withdrawal',
					component:withdrawal
				},{
					path:'/withdrawal_cert',
					name:'withdrawal_cert',
					component:withdrawal_cert
				},{
					path:'/redList',
					name:'redList',
					component:redList
				},{
					path:'/redRecord',
					name:'redRecord',
					component:redRecord
				},{
					path:'/rentList',
					name:'rentList',
					component:rentList
				},{
					path:'/rentRecord',
					name:'rentRecord',
					component:rentRecord
				},{
					path:'/promotionList',
					name:'promotionList',
					component:promotionList
				},{
					path:'/promotionRecord',
					name:'promotionRecord',
					component:promotionRecord
				},{
					path:'/system',
					name:'system', 
					component:system
				},{
					path:'/service',
					name:'service', 
					component:service
				},{
					path:'/rechargeConfig',
					name:'rechargeConfig',
					component:rechargeConfig
				},
				{
					path:'/UserList',
					name:'UserList',
					component:UserList
				},{
					path:'/Contacts',
					name:'Contacts',
					component:Contacts
				},{
					path:'/OrderList',
					name:'OrderList',
					component:OrderList
				},{
					path:'/ClosingOrder',
					name:'ClosingOrder',
					component:ClosingOrder
				},{
					path:'/Permission/roleList',
					name:'roleList',
					component:roleList
				},{
					path:'/Permission/permissionList',
					name:'permissionList',
					component:permissionList
				},{
					path:'/manager',
					name:'managerList',
					component:managerList
				},{
					path:'/payway',
					name:'PaywayList',
					component:PaywayList
				}
			]
		  }
	  ]
    },{
      path: '/Login',
      name: 'Login',
      component: Login
    }
  ]
})
