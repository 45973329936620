<template>
	<div>
		<el-form :model="goodsInfo" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
			<el-form-item label="支付名称:" prop="payway">
				<el-input size="small" v-model="goodsInfo.payway"></el-input>
			</el-form-item>
			
			<el-form-item label="支付图片:" prop="img_url">
				<el-upload class="avatar-uploader" :action="this.$http_host + '/Admin/Upload/UploadImage'" :headers="headers" :show-file-list="false"
					:on-success="UploadSuccess"
					:before-upload="beforeUpload"
					:on-error="UploadError"
				>
				  <img v-if="goodsInfo.img_url" :src="this.$http_host + goodsInfo.img_url" class="avatar">
				  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			
			<el-form-item label="支付备注:" prop="payname">
				<el-input type="text" size="small" placeholder="请输入" v-model="goodsInfo.payname">
				</el-input>
			</el-form-item>
			
			<el-form-item label="权重:" prop="sorts">
				<el-input type="number" size="small" placeholder="请输入" v-model="goodsInfo.sorts">
				</el-input>
			</el-form-item>
			
			<el-form-item label="是否开启支付:" prop="status">
				<el-switch v-model="goodsInfo.status" :active-value="1" :inactive-value="0"></el-switch>
			</el-form-item>
			<el-form-item label="是否为默认支付:" prop="is_default">
				<el-switch v-model="goodsInfo.is_default" :active-value="1" :inactive-value="0"></el-switch>
			</el-form-item>

			<el-form-item style="display: flex; flex-direction: row; justify-content: flex-end;">
				<el-button type="primary" @click="submitForm('ruleForm')">确认修改</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		props:['goodsInfo'],
	    data() {
	      return {
				headers:{},
				rules: {
				  payway: [
				    { required: true, message: '请输入支付名称', trigger: 'blur' },
				  ],
				  img_url: [
				    { required: true, message: '请上传图片', trigger: 'blur' }
				  ],
				  payname: [
				    { required: true, message: '请输入支付备注', trigger: 'blur' }
				  ],
				  sorts: [
				    { required: true, message: '请输入权重', trigger: 'blur' }
				  ]
				}
	      };
	    },
		created() {
			this.headers.Authorization = localStorage.getItem('access_token');
			
			console.log("goodsInfo", this.goodsInfo);
		},
	    methods: {
			beforeUpload(file){
			},
			UploadSuccess(res, file, fileList){
				if(res.code == 200)
				{
					this.ruleForm.img_url = res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadError(){
				return this.$message({ message: '上传失败，请重试', type: 'warning' });
			},
			submitApi(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '保存中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Payway/editPayway", this.goodsInfo).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '编辑成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			submitForm(formName) {
				let _this = this;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						_this.submitApi();
					} else {
						return that.$message({
							 message: '提交失败',
							 type: 'warning',
						});
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
	    }
	  }
</script>

<style scoped>
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    min-width: 90px;
	    height: 90px;
	    line-height: 90px;
	    text-align: center;
	  }
	  .avatar {
	    min-width: 90px;
	    height: 90px;
	    display: block;
	  }
</style>