<style scoped>
	#app {
		padding: 20px;
	}
	.header {
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		
	}
	.table-box {
		margin-top: 10px;
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
	}
	
	.table-header {
		padding: 15px 20px;
		font-size: 14px;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.box {
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
		font-size: 14px;
	}
	.form-item {
		height: 60px;
		width: 380px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.form-name {
		width: 200px;
		text-align: right;
	}
	.inp {
		width: 280px;
	}
	.el-button--primary {
		background-color: #1677FF!important;
	}
	.dot {
		color: #52C51A;
		padding: 10px;
	}
	.il {
		display: flex;
		flex-direction: row;
	}
	.t-box {
		border: solid 1px #dcdcdc;
		padding: 0 4px;
		border-radius: 4px;
	}
	.t-box1 {
		border: solid 1px #3da113;
		color: #3da113;
		padding: 0 4px;
		border-radius: 4px;
		background-color: #F7FFFE;
	}
	.t-edit {
		font-size: 14px;
		padding: 0 4px;
		margin-left: 10px;
	}
	.t-a {
		color: #1677FF;
		text-decoration: underline;
	}
</style>
<template>
	<div id="app">
		<el-breadcrumb class="nav" separator="/">
			<el-breadcrumb-item>红包管理</el-breadcrumb-item>
			<el-breadcrumb-item>红包记录</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="header">红包记录</div>
		
		<div class="box">
			<div class="form-item">
				<div class="form-name">红包码：</div>
				<el-input class="inp" v-model="form.red_code" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">手机号：</div>
				<el-input class="inp" v-model="form.phone" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">邀请码：</div>
				<el-input class="inp" v-model="form.invite_code" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">创建日期：</div>
				<el-date-picker class="inp" size="small" 
				      v-model="form.add_time"
				      type="daterange"
				      range-separator="-"
				      start-placeholder="开始日期"
				      end-placeholder="结束日期">
				    </el-date-picker>
			</div>
			<div class="form-item">
				<div class="form-name"></div>
				<div style="width: 280px;">
				<el-button size="medium" @click="reset">重置</el-button>
				<el-button size="medium" type="primary" @click="search">查询</el-button>
				</div>
			</div>
		</div>
		
		<div class="table-box">
			<div class="table-header">
				<div>表格</div>
				<div><i style="font-size: 18px; display: none;" class="el-icon-setting"></i></div>
			</div>
			<el-table :data="tableData" border
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)">
			    <el-table-column prop="oid" label="序" width="60"></el-table-column>
				<el-table-column prop="name" label="后台昵称-(角色)" width="140">
					<template slot-scope="scope">
						{{admin.username}}<span class="dot">●</span>{{admin.rule}}
					</template>
				</el-table-column>
				<el-table-column prop="red_code" label="红包码" ></el-table-column>
			    <el-table-column prop="phone" label="手机号" ></el-table-column>
				<el-table-column prop="invite_code" label="邀请码" ></el-table-column>
				<el-table-column prop="money" label="金额"></el-table-column>
				<el-table-column prop="ip" label="ip"></el-table-column>
				<el-table-column prop="add_time" label="创建日期"></el-table-column>
			  </el-table>
			  
			  <div  style="flex-grow: 1; display: flex; flex-direction: row; justify-content: flex-end; margin: 10px 10px 10px 0;">
			  <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="handleCurrentChange"
			        :current-page="currentPage"
			        :page-sizes="[10, 20, 50, 100]"
			        :page-size="limit"
			        layout="total, sizes, prev, pager, next, jumper"
			        :total="total">
			      </el-pagination>
			  </div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				form:{
					red_code:'',
					phone:'',
					invite_code:'',
					add_time:''
				},
				loading:false,
				currentPage:1,
				tableData: [],
				total:0,
				limit:10,
				admin:{},
			}
		},
		created() {
			this.getData();
		},
		methods:{
			reset(){
				this.form = {
					red_code:'',
					phone:'',
					invite_code:'',
					add_time:''
				};
			},
			search(){
				this.getData();
			},
			getData(){
				let that = this;
				that.loading = true;
				that.$api.post("/Red/redRecord", {page:this.currentPage, limit:this.limit, ...this.form}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
					that.admin = res.data.admin;
				}).catch(res=>{
					that.loading = false;
				});
			},
			handleSizeChange(e){
				this.limit = e;
				this.getData();
			},
			handleCurrentChange(e){
				this.currentPage = e;
				this.getData();
			},
			formatTimestamp(timestamp) {
				// 将时间戳转换为毫秒
				const date = new Date(timestamp * 1000);
				// 获取日期和时间的各个部分
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				// 格式化为字符串
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			},
		}
		
	}
</script>

