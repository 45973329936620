<style scoped>
	#app {
		padding: 20px;
	}
	.header {
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		
	}
	.table-box {
		margin-top: 10px;
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
	}
	
	.table-header {
		padding: 15px 20px;
		font-size: 14px;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.box {
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
		font-size: 14px;
	}
	.form-item {
		height: 60px;
		width: 380px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.form-name {
		width: 200px;
		text-align: right;
	}
	.inp {
		width: 280px;
	}
	.el-button--primary {
		background-color: #1677FF!important;
	}
	.dot {
		color: #52C51A;
		padding: 10px;
	}
	.il {
		display: flex;
		flex-direction: row;
	}
	.t-box {
		border: solid 1px #dcdcdc;
		padding: 0 4px;
		border-radius: 4px;
	}
	.t-box1 {
		border: solid 1px #3da113;
		color: #3da113;
		padding: 0 4px;
		border-radius: 4px;
		background-color: #F7FFFE;
	}
	.t-edit {
		font-size: 14px;
		padding: 0 4px;
		margin-left: 10px;
	}
	.t-a {
		color: #1677FF;
		text-decoration: underline;
	}
</style>
<template>
	<div id="app">
		<el-breadcrumb class="nav" separator="/">
			<el-breadcrumb-item>租赁管理</el-breadcrumb-item>
			<el-breadcrumb-item>租赁记录</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="header">租赁记录</div>
		
		<div class="box">
			<div class="form-item">
				<div class="form-name">手机号：</div>
				<el-input class="inp" v-model="form.phone" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">租赁设备状态：</div>
				<el-select class="inp" v-model="form.status" size="small">
					<el-option :value="1" label="正常"></el-option>
					<el-option :value="2" label="到期"></el-option>
				</el-select>
			</div>
			<div class="form-item">
				<div class="form-name">创建日期：</div>
				<el-date-picker class="inp" size="small" 
				      v-model="form.add_time"
				      type="daterange"
				      range-separator="-"
				      start-placeholder="开始日期"
				      end-placeholder="结束日期">
				    </el-date-picker>
			</div>
			<div class="form-item">
				<div class="form-name"></div>
				<div style="width: 280px;">
				<el-button size="medium" @click="reset">重置</el-button>
				<el-button size="medium" type="primary" @click="search">查询</el-button>
				</div>
			</div>
		</div>
		
		<div class="table-box">
			<div class="table-header">
				<div>表格</div>
				<div><i style="font-size: 18px; display: none;" class="el-icon-setting"></i></div>
			</div>
			<el-table :data="tableData" border 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)">
			    <el-table-column prop="oid" label="序" width="60"></el-table-column>
				<el-table-column prop="name" label="后台昵称-(角色)" width="140">
					<template slot-scope="scope">
						{{admin.username}}<span class="dot">●</span>{{admin.rule}}
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="手机号" width="100"></el-table-column>
			    <el-table-column prop="goods_name" label="设备名称" width="100"></el-table-column>
				<el-table-column prop="total_price" label="租赁金额" width="80"></el-table-column>
				<el-table-column prop="number" label="租赁数量" width="80"></el-table-column>
				<el-table-column prop="discount" label="折扣(5-5%)" width="100"></el-table-column>
				<el-table-column prop="discount_val" label="折扣金额" width="80"></el-table-column>
				<el-table-column prop="total_price" label="花费金额" width="80"></el-table-column>
				<el-table-column prop="days" label="周期(单位:天)" width="110"></el-table-column>
				<el-table-column prop="hour_earn" label="每小时收益" width="100"></el-table-column>
				<el-table-column prop="is_back" label="是否返回本金" width="110">
					<template slot-scope="scope">
						<div class="il">
							<div class="t-box" v-show="scope.row.is_back == 0">否</div>
							<div class="t-box1" v-show="scope.row.is_back == 1">是</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="get_hour" label="已领取小时" width="95"></el-table-column>
				<el-table-column prop="get_money" label="已领取金额" width="95"></el-table-column>
				<el-table-column prop="status" label="设备租赁状态" width="110">
					<template slot-scope="scope">
						<div class="il">
							<div class="t-box" v-show="scope.row.status == 2">到期</div>
							<div class="t-box1" v-show="scope.row.status == 1">正常</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="buyer_ip" label="ip" width="120"></el-table-column>
				<el-table-column prop="end_time" label="到期时间" width="100">
					<template slot-scope="scope">
						{{formatTimestamp(scope.row.end_time)}}
					</template>
				</el-table-column>
				<el-table-column prop="has_money" label="可领取收益金额" width="120"></el-table-column>
				<el-table-column prop="has_hour" label="可领取收益小时" width="120"></el-table-column>
				<el-table-column prop="work_hour" label="工作小时" width="80"></el-table-column>
				<el-table-column prop="add_time" label="创建日期" width="100">
					<template slot-scope="scope">
						{{formatTimestamp(scope.row.add_time)}}
					</template>
				</el-table-column>
				<el-table-column prop="is_top" label="操作" width="140" fixed="right">
					<template slot-scope="scope">
						<div style="display: flex; flex-direction: row; justify-content: center;">
							<el-link type="primary" style="margin-right: 15px;" @click="editFunc(scope)">修改收益</el-link>
							
							<el-popover title="删除!" placement="top"  width="160">
							  <p>确认删除当前数据？</p>
							  <div style="text-align: right; margin: 0">
							    <el-button size="mini"  @click="$emit('doClose')">取消</el-button>
							    <el-button type="primary" size="mini" @click="delFunc(scope)">确定</el-button>
							  </div>
							  <el-link type="danger" slot="reference">删除</el-link>
							</el-popover>
						</div>
					</template>
				</el-table-column>
			  </el-table>
			  
			  <div  style="flex-grow: 1; display: flex; flex-direction: row; justify-content: flex-end; margin: 10px 10px 10px 0;">
			  <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="handleCurrentChange"
			        :current-page="currentPage"
			        :page-sizes="[10, 20, 50, 100]"
			        :page-size="limit"
			        layout="total, sizes, prev, pager, next, jumper"
			        :total="total">
			      </el-pagination>
			  </div>
		</div>
		
		<el-dialog title="新增" :visible.sync="dialogVisible" width="1000" :before-close="handleClose">
		  <addRent></addRent>
		</el-dialog>
		
		<el-dialog title="编辑" :visible.sync="EditVisible" width="1000" :before-close="handleClose">
		  <editRent :goodsInfo="goodsInfo"></editRent>
		</el-dialog>
	</div>
</template>

<script>
	import addRent from './addRent.vue';
	import editRent from './editRent.vue';
	export default {
		components:{
			addRent, editRent
		},
		data(){
			return {
				form:{
					phone:'',
					status:'',
					add_time:''
				},
				goodsInfo:{},
				dialogVisible:false,
				EditVisible:false,
				currentPage:1,
				tableData: [],
				total:0,
				limit:10,
				admin:{},
			}
		},
		created() {
			this.getData();
		},
		methods:{
			reset(){
				this.form = {
					phone:'',
					status:'',
					add_time:''
				};
			},
			search(){
				this.getData();
			},
			editFunc(scope){
				this.goodsInfo = scope.row;
				this.EditVisible = true;
			},
			delFunc(scope){
				this.tableData[scope.$index].DelVisible = false;
				const loading = this.$loading({
						lock: true,
						text: '删除中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Order/deleteOrder", {oid:scope.row.oid}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 200)
					{
						that.tableData.splice(scope.$index, 1);
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			getData(){
				let that = this;
				that.loading = true;
				that.$api.post("/Product/getOrder", {page:this.currentPage, limit:this.limit, ...this.form}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
					that.admin = res.data.admin;
				}).catch(res=>{
					that.loading = false;
				});
			},
			handleClose(){
				this.dialogVisible = false;
				this.EditVisible = false;
			},
			handleSizeChange(e){
				this.limit = e;
				this.getData();
			},
			handleCurrentChange(e){
				this.currentPage = e;
				this.getData();
			},
			formatTimestamp(timestamp) {
				// 将时间戳转换为毫秒
				const date = new Date(timestamp * 1000);
				// 获取日期和时间的各个部分
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				// 格式化为字符串
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			},
		}
		
	}
</script>

