<template>
  <div id="app">
		<router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  created(){
  }
}
</script>

<style>
	.el-table {
		color: #000000!important;
		font-size: 13px!important;
	}
	.el-table thead {
		color: #000000!important;
		font-size: 14px!important;
	}
	.el-switch.is-checked .el-switch__core {
		background-color: #1677FF!important;
	}
	/* switch按钮样式 */
	.switch .el-switch__label {
	    position: absolute;
	    display: none;
	    color: #fff !important;
	}
	/*打开时文字位置设置*/
	.switch .el-switch__label--right {
	    z-index: 1;
	}
	/* 调整打开时文字的显示位子 */
	.switch .el-switch__label--right span{
	    margin-right: 9px;
	}
	/*关闭时文字位置设置*/
	.switch .el-switch__label--left {
	    z-index: 1;
	}
	/* 调整关闭时文字的显示位子 */
	.switch .el-switch__label--left span{
	    margin-left: 9px;
	}
	/*显示文字*/
	.switch .el-switch__label.is-active {
	    display: block;
	}
	/* 调整按钮的宽度 */
	.switch.el-switch .el-switch__core,
	.el-switch .el-switch__label {
	     width: 60px !important;
	     margin: 0;
		 padding: 0 10px;
	}
</style>
