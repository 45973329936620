<style scoped>
	.header {
		height: 44px!important;
		background-color: #F6F6F6;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-bottom:solid 1px #EAEAEA;
	}
	
	.logo {
		width: 26px;
		height: 26px;
	}
	.logo-name {
		margin-left: 10px;
		font-weight: bold;
		font-size: 14px;
	}
	.flr {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	.avatar {
		width: 30px;
		height: 30px;
		border-radius: 15px;
		margin-right: 10px;
	}
	.nickname {
		font-size: 14px;
	}
	
	.menu-item {
		cursor: pointer;
		height: 44px; 
		line-height: 44px; 
		width: 196px;
		text-align: center; 
		
		background-color: #FFFFFF;
		border-right: solid 3px #FFFFFF;
		
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
	}
	
	.sub-menu-item {
		cursor: pointer;
		height: 44px;
		line-height: 44px; 
		width: 196px;
		text-align: center; 
		
		background-color: #F9F9F9;
		border-right: solid 3px #F9F9F9;
		
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
	}
	.menu-parent-check {
		color: #1677FF;
	}
	.menu-check {
		background-color: #E7F5FF;
		border-right: solid 3px #1677FF;
		color: #1677FF;
	}
	.menu-icon {
		width: 20px;
		margin-left: 20px;
		margin-right: 10px;
	}
	.menu-name {
		font-size: 14px;
	}
	.menu-dir {
		position: absolute;
		right: 10px;
	}
	
	.div-no-scrollbar {
	  overflow: auto; /* 保持可滚动 */
	  scrollbar-width: none; /* 对于 Firefox 隐藏滚动条 */
	}
	 
	.div-no-scrollbar::-webkit-scrollbar {
	  display: none; /* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
	}
	
</style>

<template>
	<div id="app">
		<el-container>
			<el-header class="header">
				<div class="flr">
					<img class="logo" src="../assets/logo.jpg" />
					<div class="logo-name">Rich Gold-TGO</div>
				</div>
				<el-popover placement="bottom" width="90" style="min-width: 90px!important;" trigger="click">
					<div style="text-align: center; cursor: pointer;" @click="logout"><i class="el-icon-s-fold" style="margin-right:5px;"></i>退出登录</div>
					<div class="flr" style="cursor: pointer; margin-right: 20px;" slot="reference">
						<img class="avatar" src="../assets/logo.jpg" />
						<div class="nickname">{{nickname}}</div>
					</div>
				</el-popover>
				
				
			</el-header>
			<el-container style="background-color: #F5F5F5; height: calc(100vh - 44px); ">
				<el-aside class="div-no-scrollbar" style=" width:200px; background-color: #FFFFFF; border-right: solid 1px #E7E7E7;">
					
					<div  v-for="(menu, index) in menu_list" :key="index">
						<div :class="['menu-item', (top_open == menu.id) ? 'menu-parent-check' : '']" @click="clickTopMenu(menu)">
							<div class="menu-icon"><i :class="menu.icon_class"></i></div>
							<div class="menu-name">{{menu.title}}</div>
							<div class="menu-dir"><i v-if="menu.sub_menu.length!=0" :class="[(top_open == menu.id ? 'el-icon-arrow-up':'el-icon-arrow-down')]"></i></div>
						</div>
					
						<div class="child-list" v-show="top_open == menu.id" @click="clickSubMenu(sub, menu)" v-for="(sub, ind) in menu.sub_menu" :key="ind">
							<div :class="['menu-item', (sub_open == sub.id) ? 'menu-check' : 'sub-menu-item']"> <!--  -->
								<div class="menu-icon"></div>
								<div class="menu-name">{{sub.title}}</div>
								<div class="menu-dir"></div>
							</div>
						</div>
					</div>
					
				</el-aside>
				<router-view style="flex-grow: 1; height: calc(100vh - 85px); overflow-y: auto;  overflow-x: hidden; width:0;"></router-view>
			</el-container>
		</el-container>
	</div>
</template>

<script>
import { watch } from 'vue';
  export default {
    name: 'MainFrame',
    data(){
      return {
		  top_open:1,
		  sub_open:0,
		  nickname:"",
		  openIndex:'',
		  menu_list:[]
      }
    },
	created() {
		this.menu_list = JSON.parse( localStorage.getItem("MENU_LIST") );
		this.nickname = this.$store.state.nickname;
		this.$router.beforeEach((to, from, next) => {
			this.openIndex = to.path;
			next();
		});
	},
	mounted(){
		// 刷新页面，重置已点击菜单
		this.openIndex = this.$route.path;
		if(this.openIndex == "/")
		{
			this.$router.push("/Dashboard");
		}
		let flg = false;
		for(let i = 0; i < this.menu_list.length; i++){
			if(flg) break;
			if(this.menu_list[i].url == this.openIndex){
				this.top_open = this.menu_list[i].id;
				break;
			}
			for(let j = 0; j < this.menu_list[i].sub_menu.length; j++){
				if(this.menu_list[i].sub_menu[j].url == this.openIndex){
					this.top_open = this.menu_list[i].id;
					this.sub_open = this.menu_list[i].sub_menu[j].id;
					flg = true;
					break;
				}
			}
		}
	},
	methods:{
		clickTopMenu(menu){
			if(this.top_open == menu.id) {
				this.top_open = 0;
				return;
			}
			this.top_open = menu.id;
			if(this.$route.path == menu.url){
				this.$router.go(0);
			}else if(menu.url != ""){
				this.$router.push(menu.url);
			}
		},
		clickSubMenu(sub, parent){
			this.sub_open = sub.id;
			if(this.$route.path == sub.url){
				this.$router.go(0);
			}else{
				this.$router.push(sub.url);
			}
		},
		changePassApi(){
			let that = this;	
			if(this.old_password.length.length < 6 || this.new_password.length <6 || this.new_password1.length < 6)
			{
				return that.$message({
					 message: '请输入完整的密码',
					 type: 'warning',
				});
			}
			if(this.new_password != this.new_password1)
			{
				return that.$message({
					 message: '新前后密码不一致',
					 type: 'warning',
				});
			}
			const loading = this.$loading({
					lock: true,
					text: '修改密码中',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			
			that.$api.post("/Admin/changePassword", {old_password:that.old_password, new_password:that.new_password}).then(res=>{
				loading.close()
				if(res.code == 1)
				{
					return that.$message({
						 message: res.msg,
						 type: 'success',
					});
				}else{
					return that.$message({
						 message: res.msg,
						 type: 'warning',
					});
				}
			}).catch(err=>{
				loading.close()
			});
		},
		passwordClose(){
			this.old_password = "";
			this.new_password = "";
			this.new_password1= "";
		},
		refresh(){

		},
		logout(){
			this.$store.commit("Logout");
			const loading = this.$loading({
				lock: true, 
				text: '退出登录中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			setTimeout(() => {
				loading.close();
				this.$router.push("/Login");
			}, 800);
					
					
		},
		tagClick(e){
			this.$router.push(e);
		},
		tagClose(e){
			let new_tags = [];
			let index = -1;
			this.tags.forEach((k,v)=>{
				if(k.path != e)
				{
					new_tags.push(k);
				}else{
					index = v;
				}
			});
			if( index != -1) index -=1;
			if( index < 0 ) index = 0;
			this.tags = new_tags;
			this.$router.push(this.tags.at(index).path);
			this.saveTags();
		},
		onSelect(path)
		{
			let item = null;
			this.menu_list.forEach((k,v)=>{
				k.sub_menu.forEach( (kk, vv)=>{
					if(path == kk.url)
					{
						item = kk;
					}
				})
			})
			let flag = false;
			this.tags.forEach((k, v)=>{
				if(k.path == item.url)
				{
					flag = true;
				}
			})
			if(!flag)
			{
				this.tags.push({name:item.title, path:item.url, close:true});
			}
			this.saveTags();
		},
		saveTags(){
			localStorage.setItem("TagsLine", JSON.stringify(this.tags));
		}
	}
  }
</script>