<style scoped>
	#app {
		padding: 20px;
	}
	.header {
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		
	}
	.table-box {
		margin-top: 10px;
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
	}
	
	.table-header {
		padding: 15px 20px;
		font-size: 14px;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.box {
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
		font-size: 14px;
	}
	.form-item {
		height: 60px;
		width: 380px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.form-name {
		width: 200px;
		text-align: right;
	}
	.inp {
		width: 280px;
	}
	.el-button--primary {
		background-color: #1677FF!important;
	}
	.dot {
		color: #52C51A;
		padding: 10px;
	}
	.il {
		display: flex;
		flex-direction: row;
	}
	.t-box {
		border: solid 1px #dcdcdc;
		padding: 0 4px;
		border-radius: 4px;
	}
	.t-box1 {
		border: solid 1px #3da113;
		color: #3da113;
		padding: 0 4px;
		border-radius: 4px;
		background-color: #F7FFFE;
	}
	.t-edit {
		font-size: 14px;
		padding: 0 4px;
		margin-left: 10px;
	}
	.t-a {
		color: #1677FF;
		text-decoration: underline;
	}
</style>
<template>
	<div id="app">
		<el-breadcrumb class="nav" separator="/">
			<el-breadcrumb-item>系统设置</el-breadcrumb-item>
			<el-breadcrumb-item>客服</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="header">客服</div>
		
		<div class="table-box">
			<div class="table-header">
				<div>表格</div>
				<div><el-button type="primary" size="small" style="margin-right: 40px;" @click="addFunc">新增</el-button> <i style="font-size: 18px; display: none;" class="el-icon-setting"></i></div>
			</div>
			<el-table :data="tableData" border >
			    <el-table-column prop="id" label="序" width="80"></el-table-column>
			    <el-table-column prop="name" label="后台昵称-(角色)" width="140">
			    	<template slot-scope="scope">
			    		{{admin.username}}<span class="dot">●</span>{{admin.rule}}
			    	</template>
			    </el-table-column>
				<el-table-column prop="name" label="名称" width="140"></el-table-column>
				<el-table-column prop="img_url" label="图标" width="80">
					<template slot-scope="scope">
						<img  :src="$http_host + scope.row.img_url" style="height: 40px;"/>
					</template>
				</el-table-column>
				<el-table-column prop="url" label="客服列表"></el-table-column>
				<el-table-column prop="sorts" label="排序(大->小)" width="120"></el-table-column>
				<el-table-column prop="add_time" label="创建日期" width="100"></el-table-column>
				<el-table-column prop="is_top" label="操作" width="140" >
					<template slot-scope="scope">
						<div style="display: flex; flex-direction: row; justify-content: center;">
							<el-link type="primary" style="margin-right: 15px;" @click="editFunc(scope)">编辑</el-link>
							
							<el-popover title="删除!" placement="top"  width="160" v-model="scope.row.DelVisible">
							  <p>确认删除当前数据？</p>
							  <div style="text-align: right; margin: 0">
								<el-button size="mini"  @click="scope.row.DelVisible = false">取消</el-button>
								<el-button type="primary" size="mini" @click="delFunc(scope)">确定</el-button>
							  </div>
							  <el-link type="danger" slot="reference">删除</el-link>
							</el-popover>
							
						</div>
					</template>
				</el-table-column>
			  </el-table>
			  
			  <div  style="flex-grow: 1; display: flex; flex-direction: row; justify-content: flex-end; margin: 10px 10px 10px 0;">
			  <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="handleCurrentChange"
			        :current-page="currentPage"
			        :page-sizes="[10, 20, 50, 100]"
			        :page-size="limit"
			        layout="total, sizes, prev, pager, next, jumper"
			        :total="total">
			      </el-pagination>
			  </div>
		</div>
		
		<el-dialog title="新增" :visible.sync="dialogVisible" width="1000" :before-close="handleClose">
		  <addService></addService>
		</el-dialog>
		
		<el-dialog title="编辑" :visible.sync="EditVisible" width="1000" :before-close="handleClose">
		  <editService :goodsInfo="goodsInfo"></editService>
		</el-dialog>
	</div>
</template>

<script>
	import addService from './addService.vue';
	import editService from './editService.vue';
	export default {
		components:{
			addService, editService
		},
		data(){
			return {
				admin:{},
				goodsInfo:{},
				dialogVisible:false,
				EditVisible:false,
				currentPage:1,
				tableData: [],
				total:0,
				limit:10,
			}
		},
		created() {
			this.getData();
		},
		methods:{
			delFunc(scope){
				this.tableData[scope.$index].DelVisible = false;
				const loading = this.$loading({
						lock: true,
						text: '删除中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Config/deleteService", {id:scope.row.id}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 200)
					{
						that.tableData.splice(scope.$index, 1);
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			
			editFunc(scope){
				this.goodsInfo = scope.row;
				this.EditVisible = true;
			},
			getData(){
				let that = this;
				that.loading = true;
				that.$api.post("/Config/getServiceList", {page:this.currentPage, limit:this.limit}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
					that.admin = res.data.admin;
				}).catch(res=>{
					that.loading = false;
				});
			},
			addFunc(){
				this.dialogVisible = true;
			},
			handleClose(){
				this.dialogVisible = false;
				this.EditVisible = false;
			},
			handleSizeChange(e){
				this.limit = e;
				this.getData();
			},
			handleCurrentChange(e){
				this.currentPage = e;
				this.getData();
			}
		}
		
	}
</script>

