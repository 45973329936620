<style scoped>
	#app {
		padding: 20px;
	}
	.header {
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		
	}
	.table-box {
		margin-top: 10px;
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
	}
	
	.table-header {
		padding: 15px 20px;
		font-size: 14px;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.box {
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
		font-size: 14px;
	}
	.form-item {
		height: 60px;
		width: 380px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.form-name {
		width: 200px;
		text-align: right;
	}
	.inp {
		width: 280px;
	}
	.el-button--primary {
		background-color: #1677FF!important;
	}
</style>
<template>
	<div id="app">
		<el-breadcrumb class="nav" separator="/">
			<el-breadcrumb-item>开发管理</el-breadcrumb-item>
			<el-breadcrumb-item>菜单列表</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="header">菜单列表</div>
		
		<div class="box">
			<div class="form-item">
				<div class="form-name">用户手机号：</div>
				<el-input class="inp" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">用户邀请码：</div>
				<el-input class="inp" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">通讯录手机号：</div>
				<el-input class="inp" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">是否注册：</div>
				<el-input class="inp" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name"></div>
				<div style="width: 280px;">
				<el-button size="medium">重置</el-button>
				<el-button size="medium" type="primary">查询</el-button>
				</div>
			</div>
		</div>
		
		<div class="table-box">
			<div class="table-header">
				<div>表格</div>
				<div><i style="font-size: 18px;" class="el-icon-setting"></i></div>
			</div>
			<el-table :data="tableData" border >
			    <el-table-column prop="date" label="日期" width="180"></el-table-column>
			    <el-table-column prop="name" label="姓名" width="180"></el-table-column>
			    <el-table-column prop="address" label="地址" width="180"></el-table-column>
				<el-table-column prop="name" label="姓名" width="180"></el-table-column>
				<el-table-column prop="address" label="地址" width="180"></el-table-column>
				<el-table-column prop="name" label="姓名"></el-table-column>
			  </el-table>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				tableData: [{
				          date: '2016-05-02',
				          name: '王小虎',
				          address: '上海市普陀区金沙江路 1518 弄'
				        }, {
				          date: '2016-05-04',
				          name: '王小虎',
				          address: '上海市普陀区金沙江路 1517 弄'
				        }, {
				          date: '2016-05-01',
				          name: '王小虎',
				          address: '上海市普陀区金沙江路 1519 弄'
				        }, {
				          date: '2016-05-03',
				          name: '王小虎',
				          address: '上海市普陀区金沙江路 1516 弄'
				        }]
			}
		}
	}
</script>

