<style scoped>
	#app {
		padding: 20px;
	}
	.header {
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		
	}
	.table-box {
		margin-top: 10px;
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
	}
	
	.table-header {
		padding: 15px 20px;
		font-size: 14px;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.box {
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
		font-size: 14px;
	}
	.form-item {
		height: 60px;
		width: 380px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.form-name {
		width: 200px;
		text-align: right;
	}
	.inp {
		width: 280px;
	}
	.el-button--primary {
		background-color: #1677FF!important;
	}
	.dot {
		color: #52C51A;
		padding: 10px;
	}
	.il {
		display: flex;
		flex-direction: row;
	}
	.t-box {
		border: solid 1px #dcdcdc;
		padding: 0 4px;
		border-radius: 4px;
	}
	.t-box1 {
		border: solid 1px #3da113;
		color: #3da113;
		padding: 0 4px;
		border-radius: 4px;
		background-color: #F7FFFE;
	}
	.t-edit {
		font-size: 14px;
		padding: 0 4px;
		margin-left: 10px;
		cursor: pointer;
	}
	.t-a {
		color: #1677FF;
		text-decoration: underline;
		cursor: pointer;
	}
</style>
<template>
	<div id="app">
		<el-breadcrumb class="nav" separator="/">
			<el-breadcrumb-item>管理员</el-breadcrumb-item>
			<el-breadcrumb-item>账号列表</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="header">账号列表</div>
		
		<div class="box">
			<div class="form-item">
				<div class="form-name">昵称：</div>
				<el-input class="inp" v-model="form.nickname" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">登录名：</div>
				<el-input class="inp" v-model="form.username" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name"></div>
				<div style="width: 280px;">
				<el-button size="medium" @click="reset">重置</el-button>
				<el-button size="medium" type="primary" @click="search">查询</el-button>
				</div>
			</div>
		</div>
		
		<div class="table-box">
			<div class="table-header">
				<div>表格</div>
				<div><el-button type="primary" size="small" style="margin-right: 40px;" @click="addFunc">新增</el-button> <i style="font-size: 18px; display: none;" class="el-icon-setting"></i></div>
			</div>
			<el-table :data="tableData" border 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
			    <el-table-column prop="aid" label="序" width="80"></el-table-column>
			    <el-table-column prop="name" label="后台昵称-(角色)" width="140">
					<template slot-scope="scope">
						{{admin.username}}<span class="dot">●</span>{{admin.rule}}
					</template>
				</el-table-column>
				<el-table-column prop="status" label="账号状态" width="85">
					<template slot-scope="scope">
						<el-switch
							 class="switch"
							 v-model="scope.row.status"
							 :active-value="1"
							 :inactive-value="0"
							 active-text="正常"
							 inactive-text="禁用"
							 @change="statusChange(scope)"
						 >
						 </el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="nickname" label="昵称" width="120"></el-table-column>
				<el-table-column prop="username" label="登录名" width="120"></el-table-column>
				<el-table-column prop="password" label="密码" width="130">
					<template slot-scope="scope">
						<div class="il">
							<div>{{scope.row.password}}</div>
							<el-popover title="修改密码" placement="top"  width="200" v-model="scope.row.passVisible">
							  <div style="line-height: 40px; height: 40px;"><span style="color:red;">*</span>新密码</div>
							  <el-input size="small" placeholder="请输入新密码" v-model="password" style="margin-bottom: 20px;"></el-input>
							  <div style="margin: 0">
							    <el-button size="mini" @click="scope.row.passVisible = false">取消</el-button>
							    <el-button type="primary" size="mini" @click="changePassword(scope)">确定</el-button>
							  </div>
							  <div slot="reference" class="t-edit"><i class="el-icon-edit"></i></div>
							</el-popover>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="group_code" label="渠道码" width="90"></el-table-column>
				<el-table-column prop="group_url" label="渠道链接" width="350">
					<template slot-scope="scope">
					{{$http_host + '/?group_code=' + scope.row.group_code}}
					</template>
				</el-table-column>
				
				<el-table-column prop="is_rand" label="是否加入随机" width="120">
					<template slot-scope="scope">
						<el-switch
							 class="switch"
							 v-model="scope.row.is_rand"
							 :active-value="1"
							 :inactive-value="0"
							 active-text="是"
							 inactive-text="否"
							 @change="randChange(scope)"
						 >
						 </el-switch>
					</template>
				</el-table-column>
				
				
				<el-table-column prop="login_ip" label="登录ip" width="140"></el-table-column>
				<el-table-column prop="ip_address" label="ip归属地" width="140"></el-table-column>
				<el-table-column prop="add_time" label="创建日期" width=""></el-table-column>
			  </el-table>
			  
			  <div  style="flex-grow: 1; display: flex; flex-direction: row; justify-content: flex-end; margin: 10px 10px 10px 0;">
			  <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="handleCurrentChange"
			        :current-page="currentPage"
			        :page-sizes="[10, 20, 50, 100]"
			        :page-size="limit"
			        layout="total, sizes, prev, pager, next, jumper"
			        :total="total">
			      </el-pagination>
			  </div>
		</div>
		
		<el-dialog title="新增" :visible.sync="dialogVisible" width="1000" :before-close="handleClose">
		  <addManager></addManager>
		</el-dialog>
		
	</div>
</template>

<script>
	
	import addManager from './addManager.vue';
	
	export default {
		components:{
			addManager
		},
		data(){
			return {
				loading:false,
				dialogVisible:false,
				admin:{},
				// 表格数据
				currentPage:1,
				tableData: [],
				total:0,
				limit:10,
				// 搜索表单
				form:{
					nickname:'',
					username:''
				},
				// 修改密码
				password:'',
			}
		},
		created() {
			this.getData();
		},
		methods:{
			addFunc(){
				this.dialogVisible = true;
			},
			handleClose(){
				this.dialogVisible = false;
			},
			chooseIndex(_index){
				this.balanceReset();
				this.index = _index;
			},
			randChange(scope){
				let _this = this;
				let _changeStatus = scope.row.is_rand;
				let _old_status   = scope.row.is_rand == '0' ? '1' : '0';
				_this.$api.post("/Admin/userChange", {aid:scope.row.aid, is_rand:_changeStatus}).then(res=>{
					if(res.code == 200){
						_this.tableData[scope.$index].is_rand = _changeStatus;
						_this.$message({
							 message: '修改成功',
							 type: 'success',
						});
					}else{
						_this.tableData[scope.$index].is_rand = _old_status;
						_this.$message({
							 message: '修改失败',
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			statusChange(scope){
				let _this = this;
				let _changeStatus = scope.row.status;
				let _old_status   = scope.row.status == '0' ? '1' : '0';
				_this.$api.post("/Admin/userChange", {aid:scope.row.aid, status:_changeStatus}).then(res=>{
					if(res.code == 200){
						_this.tableData[scope.$index].status = _changeStatus;
						_this.$message({
							 message: '修改成功',
							 type: 'success',
						});
					}else{
						_this.tableData[scope.$index].status = _old_status;
						_this.$message({
							 message: '修改失败',
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			changePassword(scope){
				let _this = this;
				_this.$api.post("/Admin/userChange", {aid:scope.row.aid, password:_this.password}).then(res=>{
					if(res.code == 200){
						_this.tableData[scope.$index].password = _this.password;
						_this.password = '';
						_this.$message({
							 message: '修改成功 ',
							 type: 'success',
						});
						_this.tableData[scope.$index].passVisible = false;
					}else{
						_this.$message({
							 message: '修改失败',
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			search(){
				this.getData();
			},
			reset(){
				this.form = {
					nickname:'',
					username:''
				};
			},
			getData(){
				let that = this;
				that.loading = true;
				that.$api.post("/Admin/AdminList", {page:this.currentPage, limit:this.limit, ...this.form}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
					that.admin = res.data.admin;
				}).catch(res=>{
					that.loading = false;
				});
			},
			handleSizeChange(e){
				this.limit = e;
				this.getData();
			},
			handleCurrentChange(e){
				this.currentPage = e;
				this.getData();
			},
			formatTimestamp(timestamp) {
				// 将时间戳转换为毫秒
				const date = new Date(timestamp * 1000);
				// 获取日期和时间的各个部分
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				// 格式化为字符串
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			},
		}
	}
</script>

