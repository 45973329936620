<style scoped>
	#app {
		padding: 20px;
	}
	.header {
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		
	}
	.table-box {
		margin-top: 10px;
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
	}
	
	.table-header {
		padding: 15px 20px;
		font-size: 14px;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.box {
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
		font-size: 14px;
	}
	.form-item {
		height: 60px;
		width: 380px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.form-name {
		width: 200px;
		text-align: right;
	}
	.inp {
		width: 280px;
	}
	.el-button--primary {
		background-color: #1677FF!important;
	}
	.dot {
		color: #52C51A;
		padding: 10px;
	}
	.il {
		display: flex;
		flex-direction: row;
	}
	.t-box {
		border: solid 1px #dcdcdc;
		padding: 0 4px;
		border-radius: 4px;
	}
	.t-edit {
		font-size: 14px;
		padding: 0 4px;
		margin-left: 10px;
	}
	.t-a {
		color: #1677FF;
		text-decoration: underline;
	}
</style>
<template>
	<div id="app">
		<div class="header">运营管理</div>
		
		<div class="box">
			<div class="form-item">
				<div class="form-name">管理员：</div>
				<el-select class="inp" size="small">
					<el-option value="zx"></el-option>
				</el-select>
			</div>
	
			<div class="form-item">
				<div class="form-name"></div>
				<div style="width: 280px;">
				<el-button size="medium">重置</el-button>
				<el-button size="medium" type="primary">查询</el-button>
				</div>
			</div>
		</div>
		
		<div class="table-box">
			<div class="table-header">
				<div>表格</div>
				<div><i style="font-size: 18px;" class="el-icon-setting"></i></div>
			</div>
			<el-table :data="tableData" border >
			    <el-table-column prop="id" label="序" width="50"></el-table-column>
			    <el-table-column prop="name" label="后台昵称-(角色)">
					<template slot-scope="scope">
						{{scope.row.manager}}<span class="dot">●</span>{{scope.row.manager_level}}
					</template>
				</el-table-column>
			    <el-table-column prop="regitser_date" label="日期">
				</el-table-column>
				<el-table-column prop="balance" label="新增用户">
				</el-table-column>
				<el-table-column prop="balance" label="新客活跃">
				</el-table-column>
				<el-table-column prop="balance" label="老客活跃">
				</el-table-column>
				<el-table-column prop="balance" label="新客充值金额">
				</el-table-column>
				<el-table-column prop="balance" label="老客充值金额">
				</el-table-column>
				<el-table-column prop="balance" label="邀请数量">
				</el-table-column>
				<el-table-column prop="balance" label="邀请充值金额">
				</el-table-column>
				<el-table-column prop="balance" label="非邀请充值金额">
				</el-table-column>
				<el-table-column prop="balance" label="充值数量">
				</el-table-column>
				<el-table-column prop="balance" label="充值金额">
				</el-table-column>
				<el-table-column prop="balance" label="提现数量">
				</el-table-column>
				<el-table-column prop="balance" label="提现金额">
				</el-table-column>
				<el-table-column prop="balance" label="真实提现数量">
				</el-table-column>
				<el-table-column prop="balance" label="真实提现金额">
				</el-table-column>
				<el-table-column prop="balance" label="首冲金额">
				</el-table-column>
				<el-table-column prop="balance" label="首冲数量">
				</el-table-column>
			  </el-table>
			  
			  <div  style="flex-grow: 1; display: flex; flex-direction: row; justify-content: flex-end; margin: 10px 10px 10px 0;">
			  <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="handleCurrentChange"
			        :current-page="currentPage4"
			        :page-sizes="[100, 200, 300, 400]"
			        :page-size="100"
			        layout="total, sizes, prev, pager, next, jumper"
			        :total="400">
			      </el-pagination>
			  </div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				tableData: [{
							id:1,
							manager:'王小虎',
							manager_level:'业务员',
							phone:'47482913',
							password:'123456',
							invite_code:'294729',
							status:1,
							user_type:1,
							balance:10,
							invited_lv1:0,
							invited_lv3:0,
							recharge_total:0,
							draw_total:0,
							pay_password:0,
							lv1_rate:0,
							lv2_rate:0,
							lv3_rate:0,
							p_phone:'1234567',
							p_invite_code:'123456',
							register_ip:'0.0.0.0',
							login_ip:'0.0.0.0',
							regitser_date: '2016-05-02'
				        }, {
							id:1,
							manager:'王小虎',
							manager_level:'业务员',
							phone:'47482913',
							password:'123456',
							invite_code:'294729',
							status:1,
							user_type:1,
							balance:10,
							invited_lv1:0,
							invited_lv3:0,
							recharge_total:0,
							draw_total:0,
							pay_password:0,
							lv1_rate:0,
							lv2_rate:0,
							lv3_rate:0,
							p_phone:'1234567',
							p_invite_code:'123456',
							register_ip:'0.0.0.0',
							login_ip:'0.0.0.0',
							regitser_date: '2016-05-02'
				        },{
							id:1,
							manager:'王小虎',
							manager_level:'业务员',
							phone:'47482913',
							password:'123456',
							invite_code:'294729',
							status:1,
							user_type:1,
							balance:10,
							invited_lv1:0,
							invited_lv3:0,
							recharge_total:0,
							draw_total:0,
							pay_password:0,
							lv1_rate:0,
							lv2_rate:0,
							lv3_rate:0,
							p_phone:'1234567',
							p_invite_code:'123456',
							register_ip:'0.0.0.0',
							login_ip:'0.0.0.0',
							regitser_date: '2016-05-02'
				        }, {
							id:1,
							manager:'王小虎',
							manager_level:'业务员',
							phone:'47482913',
							password:'123456',
							invite_code:'294729',
							status:1,
							user_type:1,
							balance:10,
							invited_lv1:0,
							invited_lv3:0,
							recharge_total:0,
							draw_total:0,
							pay_password:0,
							lv1_rate:0,
							lv2_rate:0,
							lv3_rate:0,
							p_phone:'1234567',
							p_invite_code:'123456',
							register_ip:'0.0.0.0',
							login_ip:'0.0.0.0',
							regitser_date: '2016-05-02'
				        }]
			}
		}
	}
</script>

