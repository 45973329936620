<style scoped>
	#app {
		padding: 20px;
	}
	.header {
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		
	}
	.table-box {
		margin-top: 10px;
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
	}
	
	.table-header {
		padding: 15px 20px;
		font-size: 14px;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.box {
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
		font-size: 14px;
	}
	.form-item {
		height: 60px;
		width: 380px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.form-name {
		width: 200px;
		text-align: right;
	}
	.inp {
		width: 280px;
	}
	.el-button--primary {
		background-color: #1677FF!important;
	}
	.dot {
		color: #52C51A;
		padding: 10px;
	}
	.il {
		display: flex;
		flex-direction: row;
	}
	.t-box {
		border: solid 1px #dcdcdc;
		padding: 0 4px;
		border-radius: 4px;
	}
	.t-box1 {
		border: solid 1px #3da113;
		color: #3da113;
		padding: 0 4px;
		border-radius: 4px;
		background-color: #F7FFFE;
	}
	.t-edit {
		font-size: 14px;
		padding: 0 4px;
		margin-left: 10px;
	}
	.t-a {
		color: #1677FF;
		text-decoration: underline;
	}
</style>
<template>
	<div id="app">
		<el-breadcrumb class="nav" separator="/">
			<el-breadcrumb-item>支付管理</el-breadcrumb-item>
			<el-breadcrumb-item>支付列表</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="header">支付列表</div>
		
		<div class="table-box">
			<div class="table-header">
				<div>表格</div>
				<div><el-button type="primary" size="small" style="margin-right: 40px;" @click="addFunc">新增</el-button> <i style="font-size: 18px; display: none;" class="el-icon-setting"></i></div>
			</div>
			<el-table :data="tableData" border >
			    <el-table-column prop="id" label="序" width="60"></el-table-column>
			    <el-table-column prop="payway" label="支付名称"></el-table-column>
				<el-table-column prop="img_url" label="图片">
					<template slot-scope="scope">
						<img  :src="$http_host + scope.row.img_url" style="height: 40px;"/>
					</template>
				</el-table-column>
				<el-table-column prop="payname" label="支付备注"></el-table-column>
				<el-table-column prop="sorts" label="权重" ></el-table-column>
				<el-table-column prop="status" label="是否开启支付">
					<template slot-scope="scope">
						<div class="il">
							<div class="t-box" v-show="scope.row.status == 0">否</div>
							<div class="t-box1" v-show="scope.row.status == 1">是</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="is_default" label="是否为默认支付">
					<template slot-scope="scope">
						<div class="il">
							<div class="t-box" v-show="scope.row.is_default == 0">否</div>
							<div class="t-box1" v-show="scope.row.is_default == 1">是</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="创建日期"></el-table-column>
				<el-table-column prop="is_top" label="操作" width="120" >
					<template slot-scope="scope">
						<div style="display: flex; flex-direction: row; justify-content: center;">
							<el-link type="primary" style="margin-right: 15px;" @click="editFunc(scope)">编辑</el-link>
						</div>
					</template>
				</el-table-column>
			  </el-table>
			  
			  <div  style="flex-grow: 1; display: flex; flex-direction: row; justify-content: flex-end; margin: 10px 10px 10px 0;">
			  <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="handleCurrentChange"
			        :current-page="currentPage"
			        :page-sizes="[10, 20, 50, 100]"
			        :page-size="limit"
			        layout="total, sizes, prev, pager, next, jumper"
			        :total="total">
			      </el-pagination>
			  </div>
		</div>
		
		<el-dialog title="新增" :visible.sync="dialogVisible" width="1000" :before-close="handleClose">
		  <addPayway></addPayway>
		</el-dialog>
		
		<el-dialog title="编辑" :visible.sync="EditVisible" width="1000" :before-close="handleClose">
		  <editPayway :goodsInfo="goodsInfo"></editPayway>
		</el-dialog>
	</div>
</template>

<script>
	import addPayway from './addPayway.vue';
	import editPayway from './editPayway.vue';
	export default {
		components:{
			addPayway, editPayway
		},
		data(){
			return {
				goodsInfo:{},
				dialogVisible:false,
				EditVisible:false,
				currentPage:1,
				tableData: [],
				total:0,
				limit:10,
			}
		},
		created() {
			this.getData();
		},
		methods:{
			editFunc(scope){
				this.goodsInfo = scope.row;
				this.EditVisible = true;
			},
			getData(){
				let that = this;
				that.loading = true;
				that.$api.post("/Payway/getPaywayList", {page:this.currentPage, limit:this.limit}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			addFunc(){
				this.dialogVisible = true;
			},
			handleClose(){
				this.dialogVisible = false;
				this.EditVisible = false;
			},
			handleSizeChange(e){
				this.limit = e;
				this.getData();
			},
			handleCurrentChange(e){
				this.currentPage = e;
				this.getData();
			}
		}
		
	}
</script>

