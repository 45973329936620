<style scoped>
	#app {
		padding: 20px;
	}
	.header {
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		
	}
	.table-box {
		margin-top: 10px;
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
	}
	
	.table-header {
		padding: 15px 20px;
		font-size: 14px;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.box {
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
		font-size: 14px;
	}
	.form-item {
		height: 60px;
		width: 380px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.form-name {
		width: 200px;
		text-align: right;
	}
	.inp {
		width: 280px;
	}
	.el-button--primary {
		background-color: #1677FF!important;
	}
	.dot {
		color: #52C51A;
		padding: 10px;
	}
	.il {
		display: flex;
		flex-direction: row;
	}
	.t-box {
		border: solid 1px #dcdcdc;
		padding: 0 4px;
		border-radius: 4px;
	}
	.t-box1 {
		border: solid 1px #3da113;
		color: #3da113;
		padding: 0 4px;
		border-radius: 4px;
		background-color: #F7FFFE;
	}
	.t-edit {
		font-size: 14px;
		padding: 0 4px;
		margin-left: 10px;
		cursor: pointer;
	}
	.t-a {
		color: #1677FF;
		text-decoration: underline;
		cursor: pointer;
	}
</style>
<template>
	<div id="app">
		<el-breadcrumb class="nav" separator="/">
			<el-breadcrumb-item>客户管理</el-breadcrumb-item>
			<el-breadcrumb-item>客户列表</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="header">客户列表</div>
		
		<div class="box">
			<div class="form-item">
				<div class="form-name">用户手机号：</div>
				<el-input class="inp" v-model="form.phone" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">账户状态：</div>
				<el-select class="inp" v-model="form.status" size="small">
					<el-option :value="1" label="正常"></el-option>
					<el-option :value="0" label="禁用"></el-option>
				</el-select>
			</div>
			<div class="form-item">
				<div class="form-name">邀请码：</div>
				<el-input class="inp" v-model="form.invite_code" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">注册时间：</div>
				<el-date-picker class="inp" size="small" 
				      v-model="form.register_time"
				      type="daterange"
				      range-separator="-"
				      start-placeholder="开始日期"
				      end-placeholder="结束日期">
				    </el-date-picker>
			</div>
			<div class="form-item">
				<div class="form-name"></div>
				<div style="width: 280px;">
				<el-button size="medium" @click="reset">重置</el-button>
				<el-button size="medium" type="primary" @click="search">查询</el-button>
				</div>
			</div>
		</div>
		
		<div class="table-box">
			<div class="table-header">
				<div>表格</div>
				<div><i style="font-size: 18px;" class="el-icon-setting"></i></div>
			</div>
			<el-table :data="tableData" border 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
			    <el-table-column prop="uid" label="序" width="80"></el-table-column>
			    <el-table-column prop="name" label="后台昵称-(角色)" width="140">
					<template slot-scope="scope">
						{{admin.username}}<span class="dot">●</span>{{admin.rule}}
					</template>
				</el-table-column>
			    <el-table-column prop="address" label="\n\n" width="130">
					<template slot="header">
						<div>手机</div>
						<div>密码</div>
						<div>邀请码</div>
					</template>
					<template slot-scope="scope">
						<div class="">{{scope.row.phone}}</div>
						<div class="il">
							<div>{{scope.row.password}}</div>
							<el-popover title="修改密码" placement="top"  width="200" v-model="scope.row.passVisible">
							  <div style="line-height: 40px; height: 40px;"><span style="color:red;">*</span>新密码</div>
							  <el-input size="small" placeholder="请输入新密码" v-model="password" style="margin-bottom: 20px;"></el-input>
							  <div style="margin: 0">
							    <el-button size="mini" @click="scope.row.passVisible = false">取消</el-button>
							    <el-button type="primary" size="mini" @click="changePassword(scope)">确定</el-button>
							  </div>
							  <div slot="reference" class="t-edit"><i class="el-icon-edit"></i></div>
					
							</el-popover>
							
						</div>
						<div class="">{{scope.row.invite_code}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="账号状态" width="85">
					<template slot-scope="scope">
						<el-switch
							 class="switch"
							 v-model="scope.row.status"
							 :active-value="1"
							 :inactive-value="0"
							 active-text="正常"
							 inactive-text="禁用"
							 @change="statusChange(scope)"
						 >
						 </el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="user_type" label="用户类型" width="100">
					<template slot-scope="scope">
						<div class="il">
							<div class="t-box" v-show="scope.row.user_type==1">普通</div>
							<div class="t-box1" v-show="scope.row.user_type==2">代理</div>
							
							<el-popover title="修改用户类型" placement="top"  width="200" v-model="scope.row.lvlVisible">
							  <div style="line-height: 40px; height: 40px;"><span style="color:red;">*</span>用户类型</div>
							  <el-select v-model="scope.row.user_type" style="margin-bottom: 20px;" @change="usertypeChange(scope)">
								  <el-option :value="1" label="普通"></el-option>
								  <el-option :value="2" label="代理"></el-option>
							  </el-select>
							  <div style="margin: 0; text-align: right;">
							    <el-button size="mini" @click="scope.row.lvlVisible = false">取消</el-button>
							  </div>
							  <div slot="reference" class="t-edit"><i class="el-icon-edit"></i></div>		
							</el-popover>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="balance" label="余额" width="120">
					<template slot-scope="scope">
						<div class="il">
							<div class="t-a" @click="viewBalanceDetail(scope)">{{scope.row.balance}}</div>
							
							<el-popover title="修改余额" placement="top"  width="550" v-model="scope.row.balanceVisible">
							  <div style="line-height: 40px; height: 40px;"><span style="color:red;">*</span>金额</div>
								<el-input placeholder="请输入内容" v-model="changeBalance" style="width: 530px;" class="input-with-select">
									<el-select v-model="changeBalanceType" style="width: 60px;" slot="prepend" placeholder="请选择">
										<el-option label="+" value="add"></el-option>
										<el-option label="-" value="sub"></el-option>
									</el-select>
									<el-button slot="append">={{getChangeBalance}}</el-button>
								</el-input>
							  <div style="line-height: 40px; height: 40px;"><span style="color:red;">*</span>账单类型</div>
							  <el-select v-model="balance_type" style="margin-bottom: 20px; width: 530px;">
								  <el-option v-for="(t,i) in recharge_list" :value="t.value" :label="t.label" :key="i"></el-option>
							  </el-select>
							  <div style="margin: 0">
							    <el-button size="small" @click="balanceReset">重置</el-button>
							    <el-button type="primary" size="small" @click="submitChangeBalance">确定</el-button>
							  </div>
							  <div slot="reference" class="t-edit" @click="chooseIndex(scope.$index)"><i class="el-icon-edit"></i></div>		
							</el-popover>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" label="" width="100">
					<template slot="header">
						<div>1级邀请人数</div>
						<div>3级邀请人数</div>
					</template>
					<template slot-scope="scope">
							<div class="t-a" @click="viewAgentDetail(scope, 1)">0</div>
							<div class="t-a" @click="viewAgentDetail(scope, 3)">0</div>
					</template>
				</el-table-column>
				<el-table-column prop="recharge_total" label="充值金额" width="90"></el-table-column>
				<el-table-column prop="withdrawal_total" label="提现金额" width="90"></el-table-column>
				<el-table-column prop="password" label="提现密码" width="100"></el-table-column>
				<el-table-column prop="address" label="" width="140">
					<template slot="header">
						<div>1级收益提成比例</div>
						<div>2级收益提成比例</div>
						<div>3级收益提成比例</div>
					</template>
					<template slot-scope="scope">
						<div class="">{{scope.row.rate1}}%</div>
						<div class="il">
							<div>{{scope.row.rate2}}%</div>
							
							<el-popover title="修改收益提成" placement="top"  width="550" v-model="scope.row.rateVisible">
								<div style="line-height: 40px; height: 40px;"><span style="color:red;">*</span>1级收益提成比例</div>
								<el-input placeholder="请输入" v-model="rate.rate1" style="width: 530px;"></el-input>
								
								<div style="line-height: 40px; height: 40px;"><span style="color:red;">*</span>2级收益提成比例</div>
								<el-input placeholder="请输入" v-model="rate.rate2" style="width: 530px;"></el-input>
								
								<div style="line-height: 40px; height: 40px;"><span style="color:red;">*</span>3级收益提成比例</div>
								<el-input placeholder="请输入" v-model="rate.rate3" style="width: 530px;"></el-input>

							  <div style="margin: 20px 0 30px;">
							    <el-button size="small" @click="rateReset">重置</el-button>
							    <el-button type="primary" size="small" @click="submitChangeRate">确定</el-button>
							  </div>
							  <div slot="reference" class="t-edit" @click="changeRate(scope.$index)"><i class="el-icon-edit"></i></div>		
							</el-popover>
						</div>
						<div class="">{{scope.row.rate3}}%</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" label="\n\n" width="120">
					<template slot="header">
						<div>邀请者手机号</div>
						<div>邀请者邀请码</div>
					</template>
					<template slot-scope="scope">
						<div class="">{{scope.row.p_phone}}</div>
						<div class="">{{scope.row.p_invite_code}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" label="\n\n" width="140">
					<template slot="header">
						<div>注册ip</div>
						<div>登录ip</div>
					</template>
					<template slot-scope="scope">
						<div class="">{{scope.row.register_ip}}</div>
						<div class="">{{scope.row.login_ip}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="注册时间" width="100">
					<template slot-scope="scope">
						{{formatTimestamp(scope.row.add_time)}}
					</template>
				</el-table-column>
				<el-table-column prop="name" label="操作">
					<template slot-scope="scope">
						<el-link type="primary" @click="sendDev(scope.$index)">赠送设备</el-link>
					</template>
				</el-table-column>
			  </el-table>
			  
			  <div  style="flex-grow: 1; display: flex; flex-direction: row; justify-content: flex-end; margin: 10px 10px 10px 0;">
			  <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="handleCurrentChange"
			        :current-page="currentPage"
			        :page-sizes="[10, 20, 50, 100]"
			        :page-size="limit"
			        layout="total, sizes, prev, pager, next, jumper"
			        :total="total">
			      </el-pagination>
			  </div>
		</div>
		
		<el-dialog title="赠送设备" :visible.sync="dialogVisible" width="1000" :before-close="handleClose">
		  <div style="display: flex; flex-direction: row; align-items: center; height: 40px;">
			  <div style="width:200px; text-align: right;">设备：</div>
			  <el-select v-model="select_gid" style="width: 600px;">
				  <el-option v-for="(dev, indx) in devList" :value="dev.gid" :label="dev.name"></el-option>
			  </el-select>
		  </div>
		  <div style="display: flex; flex-direction: row; justify-content: flex-end; margin-top: 30px;">
			  <el-button size="small"  @click="devReset">重置</el-button>
			  <el-button type="primary" size="small" @click="submitSendDev" style="margin-right: 120px;">提交</el-button>
		  </div>
		</el-dialog>
		
	</div>
</template>

<script>
	export default {
		computed:{
			getChangeBalance(){
				if(this.tableData.length < this.index ){
					return 0;
				}
				if(this.changeBalanceType == 'add'){
					return Number(this.tableData[this.index].balance) + Number(this.changeBalance);
				}else{
					return Number(this.tableData[this.index].balance) - Number(this.changeBalance);
				}
			}
		},
		data(){
			return {
				loading:false,
				recharge_list:[
					{
						value:4,
						label:'注册赠送'
					},{
						value:5,
						label:'充值'
					},{
						value:6,
						label:'充值赠送'
					},{
						value:1,
						label:'租赁设备'
					},{
						value:7,
						label:'租赁设备提成'
					},{
						value:2,
						label:'租赁设备收益'
					},{
						value:8,
						label:'租赁设备收益提成'
					},{
						value:3,
						label:'租赁设备本金'
					},{
						value:9,
						label:'抽奖奖励'
					},{
						value:10,
						label:'任务签到'
					},{
						value:11,
						label:'任务邀请'
					},{
						value:12,
						label:'提现'
					},{
						value:13,
						label:'提现返回'
					},{
						value:14,
						label:'红包'
					},{
						value:15,
						label:'上传提现凭证'
					},{
						value:16,
						label:'提现任务'
					},{
						value:17,
						label:'充值退款'
					},{
						value:18,
						label:'充值提成退款'
					}
				],
				devList:[],
				dialogVisible:false,
				select_gid:'',
				admin:{},
				// 表格数据
				currentPage:1,
				tableData: [],
				total:0,
				limit:10,
				// 搜索表单
				form:{
					phone:'',
					status:'',
					invite_code:'',
					register_time:''
				},
				// 修改密码
				password:'',
				// 修改提成
				rate:{
					rate1:0,
					rate2:0,
					rate3:0,
				},
				// 修改余额
				balance_type:'',
				changeBalance:'',
				changeBalanceType:'add',
				//
				index:0
			}
		},
		created() {
			this.getData();
			this.getDevList();
		},
		methods:{
			getDevList(){
				let that = this;
				that.$api.post("/Product/getAllDevList", {}).then(res=>{
					that.devList = res.data.list;
				}).catch(res=>{
					that.loading = false;
				});
			},
			viewBalanceDetail(scope){
				
			},
			viewAgentDetail(scope, _lvl){
				
			},
			submitSendDev(){
				let _this = this;
				let _form = {
					uid: this.tableData[this.index].uid,
					gid: this.select_gid
				};
				
				_this.$api.post("/Product/sendDev", _form).then(res=>{
					if(res.code == 200){
						_this.$message({
							 message: '赠送成功',
							 type: 'success',
						});
					}else{
						_this.$message({
							 message: '赠送失败',
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			devReset(){
				this.select_gid = '';
			},
			sendDev(_index){
				this.index = _index;
				this.dialogVisible = true;
			},
			handleClose(){
				this.dialogVisible = false;
			},
			submitChangeRate(){
				let _this = this;
				let _form = {
					uid: this.tableData[this.index].uid,
					rate1: this.rate.rate1,
					rate2: this.rate.rate2,
					rate3: this.rate.rate3,
				};
				
				_this.$api.post("/User/changeRate", _form).then(res=>{
					if(res.code == 200){
						_this.$message({
							 message: '操作成功',
							 type: 'success',
						});
						_this.tableData[_this.index].rate1 = res.data.rate1;
						_this.tableData[_this.index].rate2 = res.data.rate2;
						_this.tableData[_this.index].rate3 = res.data.rate3;
					}else{
						_this.$message({
							 message: '操作失败',
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			rateReset(){
				this.rate = {
					rate1:0,
					rate2:0,
					rate3:0,
				};
			},
			changeRate(_index){
				this.index = _index;
				this.rate.rate1 = this.tableData[_index].rate1;
				this.rate.rate2 = this.tableData[_index].rate2;
				this.rate.rate3 = this.tableData[_index].rate3;
			},
			submitChangeBalance(){
				let _this = this;
				let _change = Number(this.changeBalance);
				if(this.balance_type == ''){
					return _this.$message({
							 message: '请选择账单类型',
							 type: 'warning',
						});
				}
				if(this.changeBalanceType == 'sub'){
					_change = 0 - Number(this.changeBalance);
				}
				let _form = {
					uid: this.tableData[this.index].uid,
					change: _change,
					types:this.balance_type
				};

				_this.$api.post("/User/changeBalance", _form).then(res=>{
					if(res.code == 200){
						_this.$message({
							 message: '操作成功',
							 type: 'success',
						});
						_this.tableData[_this.index].balance = res.data.balance;
					}else{
						_this.$message({
							 message: '操作失败',
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			chooseIndex(_index){
				this.balanceReset();
				this.index = _index;
			},
			balanceReset(){
				this.balance_type = '';
				this.changeBalance = '';
				this.changeBalanceType = 'add';
			},
			usertypeChange(scope){
				let _this = this;
				let _changeType = scope.row.user_type;
				_this.$api.post("/User/userChange", {uid:scope.row.uid, user_type:_changeType}).then(res=>{
					that.loading = false;
					if(res.code == 200){
						_this.$message({
							 message: '修改成功',
							 type: 'success',
						});
					}else{
						_this.$message({
							 message: '修改失败,请重新选择',
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			statusChange(scope){
				let _this = this;
				let _changeStatus = scope.row.status;
				let _old_status   = scope.row.status == '0' ? '1' : '0';
				_this.$api.post("/User/userChange", {uid:scope.row.uid, status:_changeStatus}).then(res=>{
					if(res.code == 200){
						_this.tableData[scope.$index].status = _changeStatus;
						_this.$message({
							 message: '修改成功',
							 type: 'success',
						});
					}else{
						_this.tableData[scope.$index].status = _old_status;
						_this.$message({
							 message: '修改失败',
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			changePassword(scope){
				let _this = this;
				_this.$api.post("/User/userChange", {uid:scope.row.uid, password:_this.password}).then(res=>{
					if(res.code == 200){
						_this.tableData[scope.$index].password = _this.password;
						_this.password = '';
						_this.$message({
							 message: '修改成功 ',
							 type: 'success',
						});
						_this.tableData[scope.$index].passVisible = false;
					}else{
						_this.$message({
							 message: '修改失败',
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			search(){
				console.log(this.form);
				this.getData();
			},
			reset(){
				this.form = {
					phone:'',
					status:'',
					invite_code:'',
					register_time:''
				};
			},
			getData(){
				let that = this;
				that.loading = true;
				that.$api.post("/User/getUserList", {page:this.currentPage, limit:this.limit, ...this.form}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
					that.admin = res.data.admin;
				}).catch(res=>{
					that.loading = false;
				});
			},
			addFunc(){
				//this.dialogVisible = true;
			},
			handleSizeChange(e){
				this.limit = e;
				this.getData();
			},
			handleCurrentChange(e){
				this.currentPage = e;
				this.getData();
			},
			formatTimestamp(timestamp) {
				// 将时间戳转换为毫秒
				const date = new Date(timestamp * 1000);
				// 获取日期和时间的各个部分
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				// 格式化为字符串
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			},
		}
	}
</script>

