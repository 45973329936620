<style scoped>
	#app {
		padding: 20px;
	}
	.header {
		line-height: 60px;
		font-size: 16px;
		font-weight: bold;
		
	}
	.table-box {
		margin-top: 10px;
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
	}
	
	.table-header {
		padding: 15px 20px;
		font-size: 14px;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.box {
		background-color: white;
		width: 100%;
		border-radius: 3px;
		border:solid 1px #ECECEC;
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
		font-size: 14px;
	}
	.form-item {
		height: 60px;
		width: 380px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.form-name {
		width: 200px;
		text-align: right;
	}
	.inp {
		width: 280px;
	}
	.el-button--primary {
		background-color: #1677FF!important;
	}
	.dot {
		color: #52C51A;
		padding: 10px;
	}
	.il {
		display: flex;
		flex-direction: row;
	}
	.t-box {
		border: solid 1px #dcdcdc;
		padding: 0 4px;
		border-radius: 4px;
	}
	.t-edit {
		font-size: 14px;
		padding: 0 4px;
		margin-left: 10px;
	}
	.t-a {
		color: #1677FF;
		text-decoration: underline;
	}
</style>
<template>
	<div id="app">
		<el-breadcrumb class="nav" separator="/">
			<el-breadcrumb-item>客户管理</el-breadcrumb-item>
			<el-breadcrumb-item>通讯录</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="header">通讯录</div>
		
		<div class="box">
			<div class="form-item">
				<div class="form-name">用户手机号：</div>
				<el-input class="inp" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">用户邀请码：</div>
				<el-input class="inp" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">通讯录手机号：</div>
				<el-input class="inp" size="small" placeholder="请输入"></el-input>
			</div>
			<div class="form-item">
				<div class="form-name">是否注册：</div>
				<el-select class="inp" v-model="form.is_reg" size="small">
					<el-option value="1" label="是"></el-option>
					<el-option value="2" label="否"></el-option>
				</el-select>
			</div>
			<div class="form-item">
				<div class="form-name"></div>
				<div style="width: 280px;">
				<el-button size="medium">重置</el-button>
				<el-button size="medium" type="primary">查询</el-button>
				</div>
			</div>
		</div>
		
		<div class="table-box">
			<div class="table-header">
				<div>表格</div>
				<div><el-button type="primary" size="small" style="margin-right: 40px;">导出</el-button> <i style="font-size: 18px;" class="el-icon-setting"></i></div>
			</div>
			<el-table :data="tableData" border >
			    <el-table-column prop="id" label="序" width="80"></el-table-column>
			    <el-table-column prop="name" label="用户手机号" width="170">
					<template slot-scope="scope">
						{{scope.row.phone}}
					</template>
				</el-table-column>
			    <el-table-column prop="address" label="用户邀请码" width="180">
					<template slot-scope="scope">
						<div>{{scope.row.invite_code}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="通讯录名字" width="180">
					<template slot-scope="scope">
						<div>{{scope.row.phone}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="通讯录手机号" width="180">
					<template slot-scope="scope">
						<div>{{scope.row.phone}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" label="是否注册" width="150">
					<template slot-scope="scope">
						<div class="il">
							<div class="t-box">否</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="balance" label="IP" width="180">
					<template slot-scope="scope">
						<div class="">{{scope.row.register_ip}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="balance" label="ip所属地" width="180">
					<template slot-scope="scope">
						<div class="">{{scope.row.register_ip}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="regitser_date" label="上传日期"></el-table-column>
			  </el-table>
			  
			  <div  style="flex-grow: 1; display: flex; flex-direction: row; justify-content: flex-end; margin: 10px 10px 10px 0;">
			  <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="handleCurrentChange"
			        :current-page="currentPage"
			        :page-sizes="[100, 200, 300, 400]"
			        :page-size="100"
			        layout="total, sizes, prev, pager, next, jumper"
			        :total="400">
			      </el-pagination>
			  </div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				tableData: [],
				currentPage:1,
				form:{
					is_reg:''
				}
			}
		},
		methods:{
			handleSizeChange(e){
				
			},
			handleCurrentChange(e){
				
			}
		}
	}
</script>

