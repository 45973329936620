<template>
	<div>
		<el-form :model="goodsInfo" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
			
			<el-form-item label="充值类型:" prop="paytype">
				<el-select style="width: 700px;" v-model="goodsInfo.paytype">
					<el-option value="法币" label="法币"></el-option>
					<el-option value="USDT" label="USDT"></el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item label="金额:" prop="amount">
				<el-input style="width: 700px;" type="number" size="small" placeholder="请输入" v-model="goodsInfo.amount">
				</el-input>
			</el-form-item>
			
			<el-form-item label="赠送金额:" prop="award">
				<el-input style="width: 700px;" type="number" size="small" placeholder="请输入" v-model="goodsInfo.award">
				</el-input>
			</el-form-item>
			
			<el-form-item label="是否热门:" prop="is_hot">
				<el-switch v-model="goodsInfo.is_hot" :active-value="1" :inactive-value="0"></el-switch>
			</el-form-item>

			<el-form-item style="display: flex; flex-direction: row; justify-content: flex-end;">
				<el-button type="primary" @click="submitForm('ruleForm')">确认修改</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		props:['goodsInfo'],
	    data() {
	      return {
				headers:{},
				rules: {
				  award: [{required: true, message: '请输入赠送金额，不送为0', trigger: 'blur'}],
				  amount: [{required: true, message: '请输入金额', trigger: 'blur'}],
				  paytype: [{required: true, message: '请选择充值类型', trigger: 'blur'}]
				}
	      };
	    },
	    methods: {
			submitApi(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '保存中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Payway/editPayconfig", this.goodsInfo).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '编辑成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			submitForm(formName) {
				let _this = this;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						_this.submitApi();
					} else {
						return that.$message({
							 message: '提交失败',
							 type: 'warning',
						});
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
	    }
	  }
</script>

<style scoped>
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    min-width: 90px;
	    height: 90px;
	    line-height: 90px;
	    text-align: center;
	  }
	  .avatar {
	    min-width: 90px;
	    height: 90px;
	    display: block;
	  }
</style>